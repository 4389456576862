import {imageBaseUrl} from "../redux/apis/api";

export const hajjContent = 'hajj_content';
export const islamicLifeStyle = 'islamic_life_style';
export const islamicHistory = 'islamic_history';
export const namajLearning = 'namaj_learning';
export const isAdult = 'is_adult';
export const isSeniorCitizen = 'is_senior_citizen';
export const isChildhood = 'is_childhood';
export const isTeen = 'is_teen';
export const oneToMany = 'one_to_many';
export const oneToOne = 'one_to_one';
export const logo = imageBaseUrl + 'iQra/asset/100319d4b115382ddb31c68b0e67fe33.png';
export const tasbih = imageBaseUrl + 'iQra/web_assets/tasbih-1587628362-5469634.png';
export const dataNotFound = imageBaseUrl + 'web_assets/data_not_found-1583753721-5556394.png';
export const digitalQuran = imageBaseUrl + 'web_assets/Digital_Quran-1583392326-4107576.svg';
export const avater = imageBaseUrl + 'web_assets/user-avatar-1584014317-6555553.svg';
export const teacherImg1 = imageBaseUrl + 'web_assets/teacher_img_1-1584250414-4544432.png';
export const userProfilePic = imageBaseUrl + 'web_assets/userProfilePic-1584260797-8006344.jpg';
export const myCoursesLogo = imageBaseUrl + 'web_assets/myCoursesLogo-1584274847-1450072.png';
export const onDemandRegularPrice = '১০০';
export const onDemandRegularOfferPrice = '১০০';
export const clientImg1 =  imageBaseUrl + 'instructor/images/Hafez%20Mawlana_Ishak%20Mamun-1587894602-7439363.jpg';
export const clientImg2 = imageBaseUrl + 'iQra/instructor/b6d1e1b303dd7ecfe18ee1a498c80635.jpg';
export const clientImg3 = imageBaseUrl + 'iQra/instructor/874dfbb9a6d141b961d7de3f945e2f3d.jpg';
export const clientImg4 = imageBaseUrl + 'iQra/instructor/8bc317b3ca35710d9b41f8874321a568.jpg';
