import React, { Component } from 'react';
import { tasbih } from '../../utils/Constants';
import { BannerInner } from '../banner/bannerInner';
import HadithLearningBgImg from "../../assets/static/img/banner/hadith_learning.png";

class TasbihClick extends Component {

    constructor() {
        super()
        this.state = {
            count: 0,
        }
    }

    increment() {
        this.setState(
            {
                count: this.state.count + 1
            }
        );
    };

    reset() {
        this.setState(
            {
                count: 0
            }
        )
    }

    render() {
        return (
            <>
                <BannerInner innerBannerBgImg={HadithLearningBgImg} title={'Online Digital Tasbih'} />
                <div className="digitalQuarnItem">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="tasbihContent">
                                <div className="tasbihImg" >
                                        <img src={tasbih} alt="img" className="img-fluid" />
                                    </div>
                                    <h1 className="tasbihCount" >{this.state.count > 0 ? this.state.count : "0"}</h1>
                                    <button className="tasbihIncrementBtn" onClick={(e) => this.increment(e)} >Count</button>
                                    <button className="tasbihReset" onClick={(e) => this.reset(e)}>0</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TasbihClick;