import {FETCH_IFTAR_AND_SHERI} from "../index";
import http from "../../apis/service";
import {ramadan, tarabi} from "../../apis/endPoint";

export const getSehriAndIftarTime = () => async dispatch => {
    const response = await http.get(ramadan);
    dispatch({
        type: FETCH_IFTAR_AND_SHERI,
        payload: response.data.all_ramadan_info
    });
};