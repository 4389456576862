import React, { Suspense } from 'react'
import FeaturedTop from '../featuredCourse/featuredTop';
import Fajr_namaj from "../../assets/static/svg/Fajr_namaj.svg";
import IslamicLifeSingletem from './islamicLifeSingletem';
import { ramadanCalendar } from '../../utils/routes';
import LangChanger from "../../redux/helper/lngChanger";
import moment from "moment";



function RamadanCalendarItem({ getSehriAndIftarTime, t }) {
    const today = moment(new Date()).format('YYYY-MM-DD');
    return (
        <div className="digitalQuarnItem">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <FeaturedTop
                            title={'Ramadan Sehri & Iftar Time - DHAKA'}
                            para={''}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ramadanCalendarBody" style={{ width: "100%" }}>
                            <div className="table-responsive">
                                <table className="table ramadanCalendarTable table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Ramadan </th>
                                            <th>Date</th>
                                            <th>Sehri end time</th>
                                            <th>Ifter time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getSehriAndIftarTime && getSehriAndIftarTime.length > 0 && getSehriAndIftarTime.map((item, index) =>
                                            // <tr key={index} className={today === item.date_en ? "active" : ""}>
                                            //     <td>{LangChanger(t('ln'), item.ramadan_day_en, item.ramadan_day_bn)}</td>
                                            //     <td>{LangChanger(t('ln'), item.date_en, item.date_bn)}</td>
                                            //     <td>{LangChanger(t('ln'), item.sehri_time_en, item.sehri_time_bn)}</td>
                                            //     <td>{LangChanger(t('ln'), item.iftar_time_en, item.iftar_time_bn)}</td>
                                            // </tr>

                                            <tr key={index} className={today === item.date_en ? "active" : ""}>
                                                <td>{item.day_of_ramadan}</td>
                                                <td>{item.date_en}</td>
                                                <td>{new Date(item.shehri_end_time).toLocaleTimeString()}</td>
                                                <td>{new Date(item.iftar_time).toLocaleTimeString()}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RamadanCalendarItem
